@import "./global/reset.scss";
@import "./global/normalize.scss";
@import "./global/vars.scss";
@import "./global/fontface.scss";
@import "./global/placeholder.scss";
@import "./global/helpers.scss";
@import "./buttons.scss";
@import "./common.scss";
@import "./header.scss";

body {
  background: #f5f5f5;
  @extend %family-regular;
  font-size: 16;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: toRem(24);

  @include md {
    font-size: toRem(80);
  }

  @include rwd(1600) {
    font-size: toRem(150);
  }
}

main {
  overflow: hidden;
}
