.button {
  border-radius: 32px;
  text-decoration: none;
  border: 0;
  background-color: $medium-purple;
  display: inline-flex;
  align-content: center;
  cursor: pointer;
  flex-direction: row;
  padding: 16;
  color: white;
  @extend %family-bold;
  line-height: 1;
  font-size: toRem(14);
  img {
    max-width: 16px;
    max-height: 16px;
    margin-right: 8px;
  }
  @include lg {
    font-size: toRem(16);
    line-height: 1.2;
    transform: scale(1.1);

    transition: transform 0.1s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
    img {
      max-width: 24px;
      max-height: 24px;
    }
  }

  &--light {
    background-color: $medium-purple-light;
    color: $medium-purple;
  }
}
