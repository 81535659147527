header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 88px;
  display: flex;

  .logo {
    display: flex;
  }
  img {
    height: 40px;
    &:first-child {
      margin-right: 8px;
    }
  }
  @include lg {
    img {
      height: 64px;
    }
  }
}
