$black: #1c1d1f;
$medium-purple: #9e7de3;
$medium-purple-mid: #d9ccf5;
$medium-purple-light: #e6e6fa;
$aqua: #a3f2e3;

$shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;
