@font-face {
  font-family: "Fellix";
  src: url("../assets/fonts/Fellix-Regular.eot");
  src: url("../assets/fonts/Fellix-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Fellix-Regular.woff2") format("woff2"),
    url("../assets/fonts/Fellix-Regular.woff") format("woff"),
    url("../assets/fonts/Fellix-Regular.ttf") format("truetype"),
    url("../assets/fonts/Fellix-Regular.svg#Fellix-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Fellix";
  src: url("../assets/fonts/Fellix-Bold.eot");
  src: url("../assets/fonts/Fellix-Bold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Fellix-Bold.woff2") format("woff2"),
    url("../assets/fonts/Fellix-Bold.woff") format("woff"),
    url("../assets/fonts/Fellix-Bold.ttf") format("truetype"),
    url("../assets/fonts/Fellix-Bold.svg#Fellix-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
