html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

:is([type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: Fellix;
  src: url("Fellix-Regular.dd653cbf.eot");
  src: url("Fellix-Regular.dd653cbf.eot#iefix") format("embedded-opentype"), url("Fellix-Regular.334bce6c.woff2") format("woff2"), url("Fellix-Regular.b6e3f9ce.woff") format("woff"), url("Fellix-Regular.f8371352.ttf") format("truetype"), url("Fellix-Regular.0579750a.svg#Fellix-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Fellix;
  src: url("Fellix-Bold.57b1221d.eot");
  src: url("Fellix-Bold.57b1221d.eot#iefix") format("embedded-opentype"), url("Fellix-Bold.dfd772f6.woff2") format("woff2"), url("Fellix-Bold.eb6714f1.woff") format("woff"), url("Fellix-Bold.5861e144.ttf") format("truetype"), url("Fellix-Bold.a0dd7518.svg#Fellix-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body, .accordion-container .ac-text, .content h2 span {
  font-family: Fellix, sans-serif;
  font-weight: 500;
}

.accordion-container .ac-header button, footer span, .content h2, .content strong, .button {
  font-family: Fellix, sans-serif;
  font-weight: 700;
}

.isDesktop {
  display: none;
}

@media (min-width: 1200px) {
  .isDesktop {
    display: initial;
  }
}

.isMobile {
  display: initial;
}

@media (min-width: 1200px) {
  .isMobile {
    display: none;
  }
}

.button {
  cursor: pointer;
  color: #fff;
  background-color: #9e7de3;
  border: 0;
  border-radius: 32px;
  flex-direction: row;
  align-content: center;
  padding: 16px;
  font-size: .875rem;
  line-height: 1;
  text-decoration: none;
  display: inline-flex;
}

.button img {
  max-width: 16px;
  max-height: 16px;
  margin-right: 8px;
}

@media (min-width: 992px) {
  .button {
    font-size: 1rem;
    line-height: 1.2;
    transition: transform .1s ease-in-out;
    transform: scale(1.1);
  }

  .button:hover {
    transform: scale(1.2);
  }

  .button img {
    max-width: 24px;
    max-height: 24px;
  }
}

.button--light {
  color: #9e7de3;
  background-color: #e6e6fa;
}

.cta-wrapper {
  align-items: center;
  display: flex;
}

.social {
  opacity: .8;
  margin-right: 16px;
  display: flex;
}

@media (min-width: 992px) {
  .social {
    margin-right: 32px;
  }
}

.social img {
  opacity: .5;
  width: 24px;
  margin-left: 8px;
  transition: opacity .1s ease-in-out;
}

.social img:hover {
  opacity: 1;
}

@media (min-width: 992px) {
  .social img {
    width: 32px;
    margin-left: 16px;
  }
}

main {
  position: relative;
}

main:after {
  opacity: 0;
  z-index: 2;
  content: " ";
  pointer-events: none;
  background-color: #000;
  width: 100%;
  height: 140%;
  transition: opacity .2s;
  position: absolute;
  top: -20%;
  left: 0;
}

main.overlay:after {
  opacity: .4;
}

section {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

section.section {
  padding: 64px 0;
}

@media (min-width: 1200px) {
  section.section {
    padding: 160px 0;
  }
}

section.section--mobile {
  padding: 64px 0;
}

@media (min-width: 1200px) {
  section.section--mobile {
    padding: 0;
  }
}

section h1, section .h1 {
  text-align: center;
  max-width: 960px;
  margin: 0 auto 32px;
  font-size: 2rem;
  line-height: 2.5rem;
  display: block;
}

@media (min-width: 992px) {
  section h1, section .h1 {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
}

@media (min-width: 1200px) {
  section h1, section .h1 {
    margin-bottom: 56px;
    font-size: 5rem;
    line-height: 5.5rem;
  }
}

.bg-aqua {
  background-color: #a3f2e3;
}

.bg-white {
  background-color: #fff;
}

.bg-medium-purple-mid {
  background-color: #d9ccf5;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 16px;
}

@media (min-width: 1200px) {
  .container {
    padding: 0 32px;
  }
}

.container--small {
  max-width: 500px;
}

.inner-section {
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 1200px) {
  .inner-section {
    text-align: left;
    flex-direction: row;
  }
}

.content {
  max-width: 600px;
  margin-bottom: 40px;
}

@media (min-width: 1200px) {
  .content {
    margin-bottom: 0;
    margin-right: 32px;
  }
}

.content strong {
  text-transform: uppercase;
  margin-bottom: 16px;
  font-size: 1rem;
  line-height: 1.5rem;
  display: block;
}

@media (min-width: 1200px) {
  .content strong {
    font-size: 1.25rem;
    line-height: 2rem;
  }
}

.content h2 {
  font-size: 1.75rem;
  line-height: 2.25rem;
}

@media (min-width: 992px) {
  .content h2 {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
}

@media (min-width: 1200px) {
  .content h2 {
    font-size: 3rem;
    line-height: 4rem;
  }
}

.image-wrapper {
  position: relative;
}

@media (min-width: 992px) {
  .image-wrapper {
    min-height: 800px;
  }
}

.image-wrapper .image {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.image-wrapper .image img {
  width: auto;
}

.image {
  height: auto;
}

.image img {
  width: 100%;
  height: 100%;
}

@media (min-width: 1200px) {
  .image {
    min-width: 500px;
    height: 500px;
  }
}

footer {
  box-sizing: border-box;
  background-color: #f9fbfc;
  background-position-y: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 80px 0 0;
  position: relative;
  overflow: hidden;
}

footer.footer--no-bg {
  background-color: #fff;
  background-image: none;
}

footer.footer--no-bg:after {
  display: none !important;
}

footer.footer--no-bg span, footer.footer--no-bg a {
  color: #000;
}

footer img {
  width: 100%;
  height: 100%;
  padding-top: 40px;
}

footer .collaboration {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  display: flex;
}

@media (min-width: 992px) {
  footer .collaboration {
    flex-direction: row;
    margin-top: 0;
    margin-left: 16px;
    padding-top: 80px;
  }
}

footer .collaboration img {
  mix-blend-mode: multiply;
  width: 200px;
  height: auto;
  padding: 0;
}

@media (min-width: 992px) {
  footer .collaboration img {
    margin-top: 0;
    margin-left: 16px;
  }
}

footer:after {
  content: "";
  z-index: 0;
  background: linear-gradient(0deg, #00000080 0%, #0000 100%);
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: 0;
  left: 0;
}

footer .footer-content {
  padding: 0 32px;
  position: relative;
}

footer .footer-content + span {
  z-index: 2;
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
}

footer .container span {
  z-index: 2;
  text-align: center;
  width: 100%;
  display: block;
  position: absolute;
  bottom: 32px;
  left: 0;
}

footer h2, footer span {
  text-align: center;
  display: block;
}

footer h2 {
  max-width: 600px;
  margin: 0 auto;
  font-size: 2.5rem;
  line-height: 3.5rem;
}

@media (min-width: 1200px) {
  footer h2 {
    margin-bottom: 56px;
    font-size: 5rem;
    line-height: 5.5rem;
  }
}

footer span {
  color: #fff;
  font-size: .875rem;
  line-height: 1.25rem;
}

@media (min-width: 1200px) {
  footer span {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

footer span a {
  color: #fff;
  text-decoration: none;
}

#modal {
  z-index: 999;
  width: 100%;
  height: 100vh;
  display: none;
  position: fixed;
  inset: 0;
}

#modal .modal {
  width: 100vw;
  height: 100vh;
  position: relative;
}

#modal.is-open .modal_wrapper {
  opacity: 1;
}

#modal .modal_wrapper {
  opacity: 0;
  width: 94%;
  max-width: 1200px;
  transition: all .2s ease-out;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#modal .closer {
  z-index: 2;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 2.5rem;
  display: flex;
  position: absolute;
  top: 40px;
  right: 40px;
}

#modal .closer:after {
  content: "×";
  margin-top: -10px;
  display: inline-block;
}

#modal .video {
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
}

#modal iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hero {
  background-color: #fff;
}

.hero .image {
  z-index: 1;
  text-align: center;
  width: 100%;
  height: auto;
  margin-top: 48px;
  position: relative;
}

.hero .image img {
  vertical-align: text-bottom;
  width: 100%;
  height: auto;
}

.hero .video-wrapper {
  position: relative;
  overflow: hidden;
}

.hero .video-wrapper .video {
  text-align: center;
  border-radius: 32px;
  position: absolute;
  bottom: 0;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
}

.hero .video-wrapper .video video, .hero .video-wrapper .video img {
  z-index: -1;
  width: 64%;
  position: relative;
  bottom: 0;
}

@media (min-width: 1200px) {
  .hero .video-wrapper .video video, .hero .video-wrapper .video img {
    width: 49%;
    bottom: -20px;
  }
}

.hero .video-wrapper .video img {
  bottom: 10px;
}

@media (min-width: 1200px) {
  .hero .video-wrapper .video img {
    width: 50%;
    bottom: 0;
  }
}

.accordion-container .ac {
  border: 0;
}

.accordion-container .ac .ac-trigger:focus {
  opacity: 1;
  color: #9e7de3 !important;
}

.accordion-container .ac.is-active .ac-header button {
  color: #9e7de3 !important;
  opacity: 1 !important;
}

.accordion-container .ac-header button {
  color: #1c1d1f;
  font-size: 20px !important;
}

.accordion-container .ac-text {
  color: #1c1d1f;
  font-size: 16px !important;
  line-height: 24px !important;
}

@media (min-width: 1200px) {
  .accordion-container .ac-header button {
    font-size: 24px !important;
  }

  .accordion-container .ac-text {
    font-size: 18px !important;
    line-height: 24px !important;
  }
}

header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 88px;
  display: flex;
}

header .logo {
  display: flex;
}

header img {
  height: 40px;
}

header img:first-child {
  margin-right: 8px;
}

@media (min-width: 992px) {
  header img {
    height: 64px;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f5f5;
  font-size: 16px;
}

h1 {
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  h1 {
    font-size: 5rem;
  }
}

@media (min-width: 1600px) {
  h1 {
    font-size: 9.375rem;
  }
}

main {
  overflow: hidden;
}

/*# sourceMappingURL=beta.00830785.css.map */
