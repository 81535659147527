.cta-wrapper {
  display: flex;
  align-items: center;
}
.social {
  margin-right: 16px;
  @include lg {
    margin-right: 32px;
  }
  display: flex;
  opacity: 0.8;
  img {
    width: 24px;
    opacity: 0.5;
    margin-left: 8px;
    transition: opacity 0.1s ease-in-out;
    &:hover {
      opacity: 1;
    }
    @include lg {
      margin-left: 16px;
      width: 32px;
    }
  }
}

main {
  position: relative;
  &::after {
    width: 100%;
    height: 140%;
    background-color: black;
    opacity: 0;
    z-index: 2;
    content: ' ';
    position: absolute;
    top: -20%;
    pointer-events: none;
    left: 0;
    transition: opacity 0.2s ease;
  }
  &.overlay {
    &::after {
      opacity: 0.4;
    }
  }
}
section {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  &.section {
    padding: 64px 0;
    @include xl {
      padding: 160px 0;
    }
    &--mobile {
      padding: 64px 0;
      @include xl {
        padding: 0;
      }
    }
  }

  h1,
  .h1 {
    max-width: 960px;
    margin: 0 auto;
    text-align: center;
    display: block;
    font-size: toRem(32);
    line-height: toRem(40);
    margin-bottom: 32px;
    @include lg {
      font-size: toRem(40);
      line-height: toRem(56);
    }
    @include xl {
      font-size: toRem(80);
      line-height: toRem(88);
      margin-bottom: 56px;
    }
  }
}

.bg-aqua {
  background-color: $aqua;
}

.bg-white {
  background-color: white;
}

.bg-medium-purple-mid {
  background-color: $medium-purple-mid;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 16px;
  @include xl {
    padding: 0 32px;
  }
  &--small {
    max-width: 500px;
  }
}

.inner-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  @include xl {
    flex-direction: row;
    text-align: left;
  }
}

.content {
  max-width: 600px;
  margin-bottom: 40px;
  @include xl {
    margin-bottom: 0;
    margin-right: 32px;
  }
  strong {
    @extend %family-bold;
    text-transform: uppercase;
    font-size: toRem(16);
    line-height: toRem(24);
    margin-bottom: 16px;
    display: block;
    @include xl {
      font-size: toRem(20);
      line-height: toRem(32);
    }
  }
  h2 {
    @extend %family-bold;
    font-size: toRem(28);
    line-height: toRem(36);
    @include lg {
      font-size: toRem(40);
      line-height: toRem(56);
    }
    @include xl {
      font-size: toRem(48);
      line-height: toRem(64);
    }
    span {
      @extend %family-regular;
    }
  }
}

.image-wrapper {
  position: relative;
  @include lg {
    min-height: 800px;
  }
  .image {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    img {
      width: auto;
    }
  }
}

.image {
  height: auto;
  img {
    width: 100%;
    height: 100%;
  }
  @include xl {
    min-width: 100%;
    min-width: 500px;
    height: 500px;
  }
}

footer {
  position: relative;
  background-color: #f9fbfc;
  padding: 80px 0 0 0;
  &.footer--no-bg {
    background-color: #fff;
    background-image: none;
    &::after {
      display: none !important;
    }
    span,
    a {
      color: black;
    }
  }
  // background-image: url("../assets/images/liv-footer@2x.png");
  background-repeat: no-repeat;
  background-size: 100%;
  box-sizing: border-box;
  background-position-y: 100%;
  overflow: hidden;

  img {
    width: 100%;
    padding-top: 40px;
    height: 100%;
  }

  .collaboration {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include lg {
      padding-top: 80px;
      flex-direction: row;
      margin-top: 0;

      margin-left: 16px;
    }
    img {
      padding: 0;
      width: 200px;
      height: auto;
      mix-blend-mode: multiply;

      @include lg {
        margin-top: 0;

        margin-left: 16px;
      }
    }
  }
  &::after {
    width: 100%;
    height: 200px;

    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    position: absolute;
    bottom: 0;
    content: '';
    z-index: 0;
    left: 0;
  }

  .footer-content {
    position: relative;
    padding: 0 32px;
    + span {
      z-index: 2;
      position: absolute;
      bottom: 32px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .container span {
    position: absolute;
    z-index: 2;
    width: 100%;
    left: 0;
    bottom: 32;
    text-align: center;
    display: block;
  }

  h2,
  span {
    text-align: center;
    display: block;
  }
  h2 {
    max-width: 600;
    margin: 0 auto;
    font-size: toRem(40);
    line-height: toRem(56);
    @include xl {
      font-size: toRem(80);
      line-height: toRem(88);
      margin-bottom: 56px;
    }
  }
  span {
    @extend %family-bold;
    color: white;
    font-size: toRem(14);
    line-height: toRem(20);
    @include xl {
      font-size: toRem(16);
      line-height: toRem(24);
    }
    a {
      color: white;
      text-decoration: none;
    }
  }
}

#modal {
  .modal {
    position: relative;
    width: 100vw;
    height: 100vh;
  }
  &.is-open {
    .modal_wrapper {
      opacity: 1;
    }
  }
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;

  width: 100%;
  .modal_wrapper {
    top: 50%;
    left: 50%;
    width: 94%;
    opacity: 0;
    max-width: 1200px;
    position: relative;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-out;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // left: 50%;
    // top: 50%;
  }
  .closer {
    width: 40px;
    height: 40px;
    background-color: white;
    position: absolute;
    z-index: 2;
    border-radius: 50%;
    top: 40px;
    right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: toRem(40);
    cursor: pointer;
    &:after {
      display: inline-block;
      content: '\00d7'; /* This will render the 'X' */
      margin-top: -10px;
    }
  }
  .video {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding-bottom: 56.25%;
    @include lg {
    }
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.hero {
  background-color: white;
  .image {
    position: relative;
    height: auto;
    z-index: 1;
    width: 100%;
    text-align: center;
    margin-top: 48px;
    img {
      height: auto;
      vertical-align: text-bottom;

      width: 100%;
    }
  }
  .video-wrapper {
    position: relative;
    overflow: hidden;
    .video {
      position: absolute;
      text-align: center;
      overflow: hidden;
      bottom: 0;
      border-radius: 32px;
      left: 50%;
      transform: translateX(-50%);
      video,
      img {
        bottom: 0;
        position: relative;
        width: 64%;
        @include xl {
          bottom: -20px;
          width: 49%;
        }
        z-index: -1;
      }
      img {
        bottom: 10px;

        @include xl {
          bottom: 0px;
          width: 50%;
        }
      }
    }
  }
}

.accordion-container {
  .ac {
    border: 0;
  }

  .ac .ac-trigger:focus {
    color: $medium-purple !important;
    opacity: 1;
  }
  .ac.is-active {
    .ac-header button {
      color: $medium-purple !important;
      opacity: 1 !important;
    }
  }

  .ac-header button {
    font-size: 20px !important;
    color: $black;
    @extend %family-bold;
  }
  .ac-text {
    font-size: 16px !important;
    line-height: 24px !important;
    color: $black;
    @extend %family-regular;
  }
  @include xl {
    .ac-header button {
      font-size: 24px !important;
    }
    .ac-text {
      font-size: 18px !important;
      line-height: 24px !important;
    }
  }
}
